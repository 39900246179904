import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'


const ContactPage= () => {
    return(
        <Layout>
        <Head title="Contact"/>
        <h2>Contact</h2>
        <p>Best way to reach me is via twitter <a href="https://twitter.com/imtiazlatif" target="_blank" rel="noopener noreferrer" >@imtiazlatif</a></p>
        </Layout>
        
    )
}
 export default ContactPage